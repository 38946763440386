import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Search } from '@mui/icons-material';
// @mui
import {
  Grid,
  Button,
  Container,
  Stack,
  Typography,
  Card,
  CardContent,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  InputAdornment,
  Tabs,
  Tab,
  IconButton,
  Popover,
  MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Import MonetizationOnIcon
import { getUserInfoByPhone } from 'src/redux/slices/userSlice';
import {
  credit_transfer,
  get_trx_history_and_credit,
  get_trx_history_by_condition,
  get_trx_history_by_id,
  resetTransferMessage,
  reverse_trx,
} from 'src/redux/slices/creditSlice';
import Iconify from 'src/components/iconify/Iconify';
import SalesDetailModal from './modals/salesDetail';

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: '10px',
  marginTop: theme.spacing(2),
}));

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const BalanceCard = styled(Card)({
  backgroundColor: '#fff',
  padding: '16px',
  borderRadius: '10px',
});

const TransferContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const BalanceTypography = styled(Typography)({
  fontSize: '24px',
  fontWeight: 'bold',
  marginBottom: '8px',
  color: 'green',
});

const DetailContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const DividerContainer = styled(Grid)({
  width: '100%',
  borderBottom: '1px solid #ddd', // Horizontal separator line
  margin: '16px 0',
});

// ----------------------------------------------------------------------

export default function BlogPage() {
  const [open, setOpen] = useState(null);
  const [_trxId,setSelectedTrxnId] = useState(null);
  const [showModal,setShowModal] = useState(false);
  const [_detailData,setDetailData] = useState(null);

  const [phoneNo, setPhoneNo] = useState('');
  const [credit, setCredit] = useState('');
  const [creditEntered, setCreditEntered] = useState('');

  const [phoneNoError, setPhonenoError] = useState(false);
  const [creditError, setCreditError] = useState(false);
  const [creditErrorMessage, setCreditErrorMessage] = useState('');

  const transferDetail = useSelector((state) => state.auth.transferDetail);
  const transferDetailError = useSelector((state) => state.auth.transferDetailError);

  const _user = useSelector((state) => state.auth.user);
  const _trxHistories = useSelector((state) => state.credits.trx_history);
  const _credit = useSelector((state) => state.credits.credit);
  const _transfer_completed = useSelector((state) => state.credits.transfer_completed);
  const _transfer_message = useSelector((state) => state.credits.transfer_message);

  const _reverse_history_obj = useSelector((state) => state.credits.reverse_trx_history_obj);
  const _reverse_msg = useSelector((state) => state.credits.reverse_msg);
  //Reverse
  const [transactionId, setTransactionId] = useState('');
  const [showReverseDetails, setShowReverseDetails] = useState(false);
  const [reverseDetail, setReverseDetail] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const dispatch = useDispatch();

  const handleShowAllClick = async () => {
    try {
      dispatch(get_trx_history_and_credit({ user: _user.phone, fetchAll: true }));
    } catch (error) {}
  };

  const [transferDetails, setTransferDetails] = useState(null);
  const [transferDetailErrorState, setTransferDetailError] = useState(null);

  const [showDetails, setShowDetails] = useState(false);

  const currency_formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const handleTransferClick = async () => {
    // Replace the following line with logic to fetch transfer details
    try {
      if (!phoneNo || phoneNo.length == 0) {
        setPhonenoError(true);
        return;
      }
      if (!creditEntered || creditEntered.length == 0 || creditEntered < 50) {
        setCreditError(true);
        setCreditErrorMessage('Minimum Transfer Amount is 50 Birr!');
        return;
      }

      if (creditEntered > _credit.remaining_credit) {
        setCreditError(true);
        setCreditErrorMessage('Your balance is insufficient!');
        return;
      }
      setCreditError(false);
      setCreditErrorMessage('');
      dispatch(resetTransferMessage());

      const inputValue = phoneNo;
      const sanitizedInput = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
      const isValid = /^\d{10}$/.test(sanitizedInput);

      if (!isValid) {
        setPhonenoError(true);
        return;
      }

      setPhonenoError(false);
      setCreditError(false);

      await dispatch(getUserInfoByPhone(phoneNo));

      await setTransferDetails(transferDetail);

      //sender is super send with cut
      let creditAmount = creditEntered;
      //alert('sender: ' + transferDetail?.cut + ' and receiver: ' + transferDetail.role);

      if (!transferDetail?.cut || transferDetail?.cut === undefined) {
        setTransferDetailError('cut percentage not set for the receiving user!');
        setShowDetails(true);
        return;
      }

      if (transferDetail?.cut <= 0) {
        setTransferDetailError(`invalid cut amount [{transferDetail?.cut}]!`);
        setShowDetails(true);
        return;
      }

      if (_user.role === 'super') {
        creditAmount = creditEntered / (transferDetail?.cut / 100); //percentage
      } else if ((_user.role === 'Agent' || _user.role === 'SubAgent') && transferDetail.role === 'Admin') {
        creditAmount = creditEntered / (transferDetail?.cut / 100); //percentage
      } else if (_user.role === 'Agent' && transferDetail.role === 'SubAgent') {
        creditAmount = creditEntered / (transferDetail?.cut / 100); //percentage
      } else if ((_user.role === 'Agent' || _user.role === 'SubAgent') && transferDetail.role === 'Employee') {
        creditAmount = creditEntered / (transferDetail?.cut / 100); //percentage
      } else if (transferDetail.role === 'super') {
        setTransferDetailError('this transfer is not allowed');
        setShowDetails(true);
        return;
      } else if (
        (_user.role === 'Agent' && transferDetail.role === 'Agent') ||
        (_user.role === 'Admin' && transferDetail.role === 'Agent') ||
        (_user.role === 'SubAgent' && transferDetail.role === 'SubAgent') ||
        (_user.role === 'Admin' && transferDetail.role === 'super')
      ) {
        setTransferDetailError('agent to agent,admin to agent,sub-agent to sub-agent transfer not allowed!');
        setShowDetails(true);
        return;
      } /*else {
        setTransferDetailError('invalid transfer parameters!');
        setShowDetails(true);
        return;
      }*/
      setTransferDetailError(null);

      setCredit(creditAmount);

      setShowDetails(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmClick = async () => {
    //credit_transfer
    if (creditEntered > _credit.remaining_credit) {
      setCreditError(true);
      return;
    }

    let _actualAmount = credit;
    if (_user.role === 'super' || _user.role === 'Agent') {
      _actualAmount = creditEntered;
    }

    let transferObj = {
      sender: _user.phone,
      receiver: phoneNo,
      amount: credit,
      actualAmount: _actualAmount,
    };
    await dispatch(credit_transfer(transferObj));
    setShowDetails(false);
  };

  const handleCancelClick = () => {
    // Add logic to handle cancellation
    console.log('Transfer canceled!');
    setShowDetails(false);
  };

  useEffect(() => {
    if (!_trxHistories || _trxHistories.length == 0) {
      dispatch(get_trx_history_and_credit({ user: _user.phone, fetchAll: false }));
    }
  }, []);

  useEffect(() => {
    setSummary();
  }, [_trxHistories]);

  const [filter, setFilter] = useState('');

  const [_start, setStart] = useState('');
  const [_end, setEnd] = useState('');
  const [_phone, setPhone] = useState('');

  const [_trxCount, setCount] = useState(0);
  const [_totalIn, setTotalIn] = useState(0);
  const [_totalOut, setTotalOut] = useState(0);

  const [_totalInBirr, setTotalInBirr] = useState(0);
  const [_totalOutBirr, setTotalOutBirr] = useState(0);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData =
    filter.length > 0
      ? _trxHistories.filter((row) => row.purpose?.toString().toLowerCase().includes(filter.toLowerCase()))
      : _trxHistories;

  function formatDate(date) {
    let d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const handleHistorySearch = async () => {
    try {
      if (!_phone || _phone.length == 0) setPhone(null);

      if (new Date(_start) > new Date(_end)) {
        alert('invalid search criteria!');
        return;
      }

      if (_start && _start.length > 0 && (!_end || _end.length == 0)) setEnd(_start);

      if (_end && _end.length > 0 && (!_start || _start.length == 0)) setStart(_end);

      const reqObj = {
        _start,
        _end,
        _phone,
      };

      await dispatch(get_trx_history_by_condition(reqObj));
    } catch (error) {
      console.log(error);
    }
  };

  function setSummary() {
    try {
      let currentPhone = _user?.phone;
      let trxCount = _trxHistories.length;
      let totalIn = 0;
      let totalOut = 0;

      let totalInInBirr = 0;
      let totalOutInBirr = 0;

      // Calculate total in and total out
      _trxHistories.forEach((transaction) => {
        if (transaction.receiver === currentPhone) {
          totalIn += transaction.amountTransfered;
          transaction.creditInBirr && (totalInInBirr += transaction.creditInBirr);
        }
        if (transaction.sender === currentPhone) {
          totalOut += transaction.amountTransfered;
          transaction.creditInBirr && (totalOutInBirr += transaction.creditInBirr);
        }
      });

      setTotalIn(totalIn);
      setTotalOut(totalOut);

      setTotalInBirr(totalInInBirr);
      setTotalOutBirr(totalOutInBirr);

      setCount(trxCount);
    } catch (error) {}
  }

  //Reverse
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    setReverseDetail(_reverse_history_obj);
  }, [_reverse_history_obj]);

  const handleReverseClick = async () => {
    setShowReverseDetails(true);
    await dispatch(get_trx_history_by_id(transactionId));
  };

  const handleReverseConfirmClick = async () => {
    // Implement reverse confirm logic here
    await dispatch(reverse_trx(transactionId));
  };

  const handleReverseCancel = ()=>{
    setShowReverseDetails(false);
    setSelectedTrxnId(null);
  }
//trxn detail
  const handleOpenMenu = (event, key) => {
    setOpen(event.currentTarget);
    setSelectedTrxnId(key);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedTrxnId(null);
    
  };

  const closeModal = () =>{
    setShowModal(false);
    setDetailData(null);
  }

  const handleShowDetail = () => {
    if(_trxId){
      //read and set
      var selectedTrxHistory = _trxHistories.filter(t=>t._id === _trxId);
      if(selectedTrxHistory){
        setDetailData(selectedTrxHistory[0]);
      }else{
        setDetailData(null);
      }
      
    }else{
      alert('transaction id not found!');
    }
  }

  useEffect(()=>{
    if(_detailData){
      //console.log(_detailData);
      setShowModal(true);
    }else{
      setShowModal(false);
    }
  },[_detailData]);

  return (
    <>
      <Helmet>
        <title> ሃገሬ ጌምስ </title>
      </Helmet>
      {showModal && <SalesDetailModal open={showModal} onClose={closeModal} salesDetail={_detailData} isTransfer={true}/>}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Credit
          </Typography>
          {/* <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
            New Post
          </Button> */}
        </Stack>

        <Grid container spacing={2}>
          {/* First Card - Takes 1/4 of the height */}
          <Grid item xs={8}>
            <BalanceCard>
              <Typography variant="h6">Current Balance</Typography>
              <Typography variant="h4" color="primary">
                <span style={_credit && _credit.remaining_credit > 50 ? { color: 'green' } : { color: 'red' }}>
                  {`${_credit ? currency_formatter.format(_credit.remaining_credit) : 0} Birr`}
                </span>
              </Typography>
            </BalanceCard>
          </Grid>

          <Grid item xs={4}>
            <BalanceCard>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify style={{ color: 'green' }} icon={'carbon:circle-filled'} />

                  <Typography variant="body1">healthy balance</Typography>
                </span>
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <Iconify style={{ color: 'red' }} icon={'carbon:circle-filled'} />

                  <Typography variant="body1">balance running out</Typography>
                </span>
              </div>
            </BalanceCard>
          </Grid>

          {/* Right Card */}
          <Grid item xs={12}>
            <BalanceCard>
              <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Transfer" />
                {_user.role === 'super' && <Tab label="Reverse" />}
              </Tabs>
              {activeTab === 0 && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TransferContainer>
                      <TextField
                        label="Phone Number"
                        variant="outlined"
                        margin="normal"
                        value={phoneNo}
                        error={phoneNoError}
                        helperText={phoneNoError ? 'Invalid phone Number' : ''}
                        onChange={(e) => setPhoneNo(e.target.value.replace(/[^0-9]/g, '').slice(0, 10))}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <PhoneIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        label="Credit"
                        variant="outlined"
                        margin="normal"
                        value={creditEntered}
                        error={creditError}
                        helperText={creditError ? creditErrorMessage : ''}
                        onChange={(e) =>
                          setCreditEntered(e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'))
                        }
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <MonetizationOnIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Button variant="contained" color="primary" onClick={handleTransferClick}>
                        Transfer
                      </Button>
                    </TransferContainer>
                  </Grid>
                  {_transfer_message && (
                    <h6 style={{ display: 'flex', alignItems: 'center', color: 'green' }}>{_transfer_message}</h6>
                  )}
                  {showDetails && (
                    <Grid item xs={6}>
                      {transferDetailError || transferDetailErrorState ? (
                        <Typography variant="h6" style={{ padding: '4px', color: 'blueviolet' }}>
                          {transferDetailError || transferDetailErrorState}
                        </Typography>
                      ) : (
                        <DetailContainer>
                          <Typography variant="h6" style={{ padding: '4px' }}>
                            Transfer Details
                          </Typography>
                          <Typography variant="body1" style={{ padding: '4px' }}>
                            Phone No: {transferDetail?.phone_no}
                          </Typography>
                          <Typography variant="body1" style={{ padding: '4px' }}>
                            Name: {`${transferDetail?.full_name} (${transferDetail?.role})`}
                          </Typography>
                          <Typography variant="body1" style={{ padding: '4px' }}>
                            Company Name: {transferDetail?.companyName}
                          </Typography>
                          <Typography variant="body1" style={{ padding: '4px' }}>
                            Amount: {currency_formatter.format(credit)}
                          </Typography>
                          <Grid container spacing={1}>
                            <Grid item>
                              <Button variant="contained" color="primary" onClick={handleConfirmClick}>
                                Confirm
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="outlined" color="secondary" onClick={handleCancelClick}>
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </DetailContainer>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
              {activeTab === 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TransferContainer>
                      <TextField
                        label="Transaction ID"
                        variant="outlined"
                        margin="normal"
                        value={transactionId}
                        onChange={(e) => setTransactionId(e.target.value)}
                      />
                      <Button variant="contained" color="primary" onClick={handleReverseClick}>
                        Reverse
                      </Button>
                    </TransferContainer>
                  </Grid>
                  {showReverseDetails && (
                    <Grid item xs={6}>
                      {_reverse_msg ? (
                        <Typography>{_reverse_msg}</Typography>
                      ) : (
                        <DetailContainer>
                          <Typography variant="h6">Reversal Details</Typography>
                          <span>Sender: {reverseDetail?.sender}</span>
                          <span>Receiver: {reverseDetail?.receiver}</span>
                          <span>Amount(B): {reverseDetail?.creditInBirr}</span>
                          <span>Amount(C): {reverseDetail?.amountTransfered}</span>
                          <Grid container spacing={2}>
                            <Grid item>
                              <Button variant="contained" color="primary" onClick={handleReverseConfirmClick}>
                                Confirm
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button variant="contained" color="primary" onClick={handleReverseCancel}>
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </DetailContainer>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </BalanceCard>
          </Grid>

          {/* Second Card - Takes the remaining 3/4 of the height */}

          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Transaction History</Typography>
                <Stack
                  spacing={3}
                  direction={{ xs: 'column', md: 'row' }}
                  sx={{ padding: '3px', justifyContent: 'space-between' }}
                >
                  <TextField
                    label="filter"
                    variant="outlined"
                    size="small"
                    value={filter}
                    onChange={handleFilterChange}
                    style={{ marginBottom: '10px' }}
                  />
                  <TextField
                    label="phone"
                    variant="outlined"
                    size="small"
                    value={_phone}
                    onChange={(e) => setPhone(e.target.value.replace(/[^0-9]/g, '').slice(0, 10))}
                    style={{ marginBottom: '10px' }}
                  />
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    type="date"
                    value={_start}
                    onChange={(e) => setStart(e.target.value)}
                    style={{ marginBottom: '10px' }}
                  />
                  <TextField
                    label=""
                    variant="outlined"
                    size="small"
                    type="date"
                    value={_end}
                    onChange={(e) => setEnd(e.target.value)}
                    style={{ marginBottom: '10px' }}
                  />

                  <Button size="small" variant="contained" endIcon={<Search />} onClick={handleHistorySearch}>
                    Find
                  </Button>
                </Stack>
                <StyledTableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableHeadCell>Trx Id</StyledTableHeadCell>
                        <StyledTableHeadCell>Sender</StyledTableHeadCell>
                        <StyledTableHeadCell>Receiver</StyledTableHeadCell>
                        <StyledTableHeadCell>Amount In Credit</StyledTableHeadCell>
                        <StyledTableHeadCell>Amount In Birr</StyledTableHeadCell>
                        <StyledTableHeadCell>Date</StyledTableHeadCell>
                        <StyledTableHeadCell>Purpose</StyledTableHeadCell>
                        <StyledTableHeadCell>Direction</StyledTableHeadCell>
                        <StyledTableHeadCell>Reference</StyledTableHeadCell>
                        <StyledTableHeadCell>Action</StyledTableHeadCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((row, index) => (
                        <StyledTableRow key={index}>
                          {/* <TableCell>{`${row.sender} (${row.senderCompany})`}</TableCell> */}
                          <TableCell>{row._id}</TableCell>
                          <TableCell>
                            {row.senderCompany ? `${row.sender} (${row.senderCompany})` : row.sender}
                          </TableCell>
                          <TableCell>
                            {row.receiverCompany ? `${row.receiver} (${row.receiverCompany})` : row.receiver}
                          </TableCell>
                          <TableCell style={{ color: '#2065D1' }}>
                            {currency_formatter.format(row.amountTransfered)}
                          </TableCell>
                          <TableCell style={{ color: '#2065D1' }}>
                            {row.creditInBirr && currency_formatter.format(row.creditInBirr)}
                          </TableCell>
                          <TableCell>{row.created_at}</TableCell>
                          <TableCell style={{ color: 'green', fontSize: '10px' }}>
                            {row.purpose ? row.purpose : 'Transfer'}
                          </TableCell>
                          <TableCell>
                            {row.sender === _user.phone ? (
                              <span style={{ color: 'red', fontWeight: 'bold', fontSize: '10px' }}>Credit</span>
                            ) : (
                              <span style={{ color: 'green', fontWeight: 'bold', fontSize: '10px' }}>Debit</span>
                            )}
                          </TableCell>
                          <TableCell>{row.reference}</TableCell>
                          <TableCell align="right">
                            <IconButton size="large" color="inherit" onClick={(e) => handleOpenMenu(e, row._id)}>
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </CardContent>

              <div style={{ paddingLeft: '40px', paddingBottom: '10px' }}>
                <small style={{ color: 'blue' }}>summary: </small>
                <small style={{ color: 'green' }}> | trx count = {_trxCount} |</small>
                <small style={{ color: 'blue' }}>
                  {' '}
                  total in ={' '}
                  {`${currency_formatter.format(_totalIn)} (${currency_formatter.format(_totalInBirr)} birr)`} |
                </small>
                <small style={{ color: 'red' }}>
                  {' '}
                  total out ={' '}
                  {`${currency_formatter.format(_totalOut)} (${currency_formatter.format(_totalOutBirr)} birr)`} |
                </small>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button variant="contained" color="primary" onClick={handleShowAllClick}>
              Show All
            </Button>
          </Grid>
        </Grid>

        {/* <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={POSTS} />
          <BlogPostsSort options={SORT_OPTIONS} />
        </Stack>

        <Grid container spacing={3}>
          {POSTS.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid> */}
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              '& .MuiMenuItem-root': {
                px: 1,
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem onClick={() => handleShowDetail()}>
            <Iconify icon={'tabler:list-details'} sx={{ mr: 2 }} />
            Detail
          </MenuItem>
        </Popover>
      </Container>
    </>
  );
}
