import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import transactionListService from 'src/services/transactionListService';


const initialState = {
    transaction_list : [],
    transaction_loading:false,
    dashboard_data : null,
    dashboard_data_per_branch : [],
    dashboard_data_per_company : [],
    transaction_load_error:'',
    selectedPattern:null,
    refundPattern:null,
    attendanceData:null,
    attendanceLoading:false,

    detailData:null,
    detailLoading:false
}

export const get_all_transactions = createAsyncThunk(
    'getAllTransactions',
    async(thunkAPI)=>{
        try {
            const data = await transactionListService.get_all_transactions();
            //console.log(data.data);
            return data.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const get_transactions_by_criteria = createAsyncThunk(
    'getTransactionByCriteria',
    async(criteria,thunkAPI)=>{
        try {
            const data = await transactionListService.get_transactions_by_criteria(criteria);
            return data.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_dashboard_data = createAsyncThunk('get_dashboard_data',
    async(thunkAPI)=>{
        try {
            const data = await transactionListService.get_dashboard_data();
            //console.log(data);
            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_dashboard_data_per_agent_subagent = createAsyncThunk('get_dashboard_data',
    async(data,thunkAPI)=>{
        try {
            const result = await transactionListService.get_dashboard_data_per_agent_subagent(data);
            return result;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const get_dashboard_data_by_branch = createAsyncThunk('get_dashboard_data_by_branch',
    async(thunkAPI)=>{
        try {
            const data = await transactionListService.get_dashboard_data_by_branch();
            //console.log(data);
            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getTransactionDetailPerCompanyPerMonth = createAsyncThunk('get_transaction_per_company_month',
    async(dateObj,thunkAPI)=>{
        try {
            
            const data = await transactionListService.getTransactionDetailPerCompanyPerMonth(dateObj);
            //console.log(data);
            return data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getAttendance = createAsyncThunk('get_attendance',
    async(date,thunkAPI)=>{
        try {
            const data = await transactionListService.getAttendance(date);
            return data;
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const getTransactionDetailById = createAsyncThunk('get_transaction_detail_by_id',
    async(id,thunkAPI)=>{
        try {
            const data = await transactionListService.getTransactionDetailById(id);
            return data;
        } catch (error) {
            console.log(error)
            return thunkAPI.rejectWithValue(error);
        }
    }
);

export const transactionSlice = createSlice({
    name:'transactions',
    initialState,
    reducers:{
        set_selected_pattern: (state, action) => {
            //console.log('from transaction slice');
            //console.log(action.payload);
            state.selectedPattern = action.payload;
            //console.log('end from transaction slice...');
        },
        set_refund_pattern:(state,action)=>{
            state.refundPattern = action.payload;
            //console.log(state.refundPattern);
        },
        reset_detail_data:(state,action)=>{
            state.detailData = null;
        }
    },
    extraReducers:{
        [get_all_transactions.fulfilled]:(state,action)=>{
            //console.log(action.payload);
            state.transaction_list = action.payload;
            state.transaction_loading = false;
            state.transaction_load_error = '';
        },
        [get_all_transactions.pending]:(state,action)=>{
            state.transaction_loading = true;
            state.transaction_load_error = '';
        },
        [get_all_transactions.rejected]:(state,action)=>{
            state.transaction_load_error = action.payload;
            state.transaction_loading = false;
        },
        [get_transactions_by_criteria.fulfilled]:(state,action)=>{
            //console.log(action.payload);
            state.transaction_list = action.payload;
            state.transaction_loading = false;
            state.transaction_load_error = '';
        },
        [get_transactions_by_criteria.pending]:(state,action)=>{
            state.transaction_loading = true;
            state.transaction_load_error = '';
        },
        [get_transactions_by_criteria.rejected]:(state,action)=>{
            state.transaction_load_error = action.payload;
            state.transaction_loading = false;
        },
        [get_dashboard_data.fulfilled]:(state,action)=>{
            state.dashboard_data = action.payload[0];//zero'th index because it returns list of objects with a single object
        },
        [get_dashboard_data.rejected]:(state,action)=>{
             console.log('error thrown during dashboard data fetch');
            // console.log(action.payload);
        },
        [get_dashboard_data_per_agent_subagent.fulfilled]:(state,action)=>{
            state.dashboard_data = action.payload[0];//zero'th index because it returns list of objects with a single object
        },
        [get_dashboard_data_per_agent_subagent.rejected]:(state,action)=>{
             console.log('error thrown during dashboard data fetch');
            // console.log(action.payload);
        },
        //

        [get_dashboard_data_by_branch.fulfilled]:(state,action)=>{
            state.dashboard_data_per_branch = action.payload;
        },
        [get_dashboard_data_by_branch.rejected]:(state,action)=>{
             console.log('error thrown during dashboard data by branch fetch');
            // console.log(action.payload);
        },
        [getTransactionDetailPerCompanyPerMonth.fulfilled]:(state,action)=>{
            state.dashboard_data_per_company = action.payload;
        },
        [getTransactionDetailPerCompanyPerMonth.rejected]:(state,action)=>{
            console.log('error thrown during dashboard data per company branch fetch');
            //console.log(action.payload);
        },
        [getAttendance.fulfilled]:(state,action)=>{
            state.attendanceData = action.payload;
            state.attendanceLoading = false;
        },
        [getAttendance.pending]:(state,action)=>{
            state.attendanceLoading = true;
        },
        [getAttendance.rejected]:(state,action)=>{
            console.log('error thrown during attendace');
            //console.log(action.payload);
            state.attendanceLoading = false;
        },
        [getTransactionDetailById.fulfilled]:(state,action)=>{
            state.detailData = action.payload;
            state.detailLoading = false;
        },
        [getTransactionDetailById.pending]:(state,action)=>{
            state.detailLoading = true;
            state.detailData = null;
        },
        [getTransactionDetailById.rejected]:(state,action)=>{
            state.detailData = null;
            state.detailLoading = false;
        },

    }
});

export const {set_selected_pattern,set_refund_pattern,reset_detail_data} = transactionSlice.actions;
const {reducer} = transactionSlice;
export default reducer;