import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import {
  Button,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,

  Dialog,
  Container,
  Typography,
  Divider,
  FormLabel,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';

import { styled } from '@mui/material/styles';

//axios
import { useDispatch, useSelector } from 'react-redux';
import { create_company } from 'src/redux/slices/companySlice';
import { getAgentUsers } from 'src/redux/slices/userSlice';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function CompanyRegisterForm({ openDialog, handleDialogClose,company }) {

  const listOfAgents = useSelector(state=> state.auth.agent_lists);
 
  const [companyId, setCompanyId] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [creditAmount, setCreditAmount] = useState(0);
  const [systemCut,setSystemCut] = useState(0);
  const [expiryDate,setExpiryDate] = useState(null);
  const [branches, setBranches] = useState([]);
  const [branch, setBranch] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [agent,setAgent] = useState(null);
  const [subAgent,setSubAgent] = useState(null);

  const [companyIdError, setCompanyIdError] = useState(false);
  const [companyNameError, setCompanyNameError] = useState(false);
  const [creditAmountError, setCreditAmountError] = useState(false);
  const [branchesError, setBranchesError] = useState(false);


  const [removedBranches,setRemovedBranches] = useState([]);


  const loged_in_user = useSelector(state => state.auth.user);;
  

  const dispatch = useDispatch();


  useEffect(() => {
    // Check if listOfAgents is empty or undefined
    if (!listOfAgents || listOfAgents.length === 0) {
      dispatch(getAgentUsers());
      console.log(listOfAgents);
    }
    
  }, [dispatch, listOfAgents]);
 

  useEffect(()=>{
    if(company){
      setCompanyId(company.company_id);
      setCompanyName(company.company_name);
      setCreditAmount(company.credit_amount);
      setSystemCut(company.system_cut);
      setExpiryDate(company.expiry_date);
      setBranches(company.branches.map((br)=>br.branch_name));
      setIsActive(company.isActive);
      setAgent(company.agent);
      setSubAgent(company.subAgent);
    }else{
      setCompanyId('');
      setCompanyName('');
      setCreditAmount(0);
      setSystemCut(0);
      setExpiryDate('');
      setBranches([]);
      setIsActive(true);
      setAgent('');
      setSubAgent('');
    }
    
    
  },[company]);
  


  const handleClick = async () => {
    if (companyId === '' || !companyId) {
      setCompanyIdError(true);
      return;
    } else {
      setCompanyIdError(false);
    }

    if (companyName === '' || !companyName) {
      setCompanyNameError(true);
      return;
    } else {
      setCompanyNameError(false);
    }

    if (!branches || branches.length === 0) {
      setBranchesError(true);
      return;
    } else {
      setBranchesError(false);
    }

    const brnchData = [];
    for(let x = 0;x<branches.length;x++){
      var t = {branch_name:branches[x]};
      brnchData.push(t);
    }

    const companyData = {

      company_id:companyId,
      company_name:companyName,
      credit_amount:creditAmount,
      system_cut:systemCut,
      expiry_date:expiryDate,
      branches:brnchData,
      isActive,
      agent,
      subAgent,
    };

    dispatch(create_company(companyData)); //dispatch create company

    setCompanyId('');
    setCompanyName('');
    setCreditAmount(0);
    setSystemCut(0);
    setExpiryDate('');
    setBranches([]);
    setBranch('');
    setIsActive(true);
    setAgent('');
    setSubAgent('');
  };

  const addBranchRow = () => {
    if (branch) {
      var brnchs = branches;
      brnchs.push(branch);
      setBranches(brnchs);
      setBranch('');
    }
  };

  const resetBranch = () => {
    setBranches([]);
    setBranch('');
  };

  const makeid = (length) => {
   
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
  };

  const handleCompanyName = (e) => {
    if (e.target.value === '') {
      if(!company){
        setCompanyId('');
      }
      setCompanyName('');
    } else {
      if(!company){//if edit do not change company id
        var compName = e.target.value;
        var prefix = compName.replace(/[aeiou -]/gi, '');
        var compId = `${prefix}-${makeid(10)}`;
        setCompanyId(compId);
      }   
      setCompanyName(e.target.value);
      
    }
  };

  const handleDeleteBranch = (branch)=>{
    setRemovedBranches(removedBranches.push(branch));
    setBranches(branches.filter(t=>t != branch));
  }

  const createAgentList = (type) => {
    console.log(listOfAgents);
    var agents = listOfAgents?.filter(t=>t.role === type)?.map((agent)=>{
          return <MenuItem key={agent.userName} value={agent.userName}>{agent.userName}</MenuItem>
    });
    agents.unshift(<MenuItem key="empty" value="">Clear</MenuItem>);
    return agents;
}

  return (
    <>
      <Dialog fullWidth open={openDialog} onClose={handleDialogClose}>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              {loged_in_user.role === 'super' ? 'Register Company' : 'Edit Company'}
            </Typography>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                companies
              </Typography>
            </Divider>

            {/*Start Registration Form*/}
            <Stack spacing={3}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <TextField
                  error={companyNameError}
                  name="companyName"
                  label="Company Name"
                  value={companyName}
                  onChange={handleCompanyName}

                  inputProps={{
                    readOnly: loged_in_user.role === 'Agent' ? true : false,
                  }}

                  sx={{ width: '45%' }} 
                />
                <TextField
                  error={companyIdError}
                  name="companyId"
                  label="Company Id"
                  value={companyId}
                  onChange={(e) => setCompanyId(e.target.value)}
                  inputProps={{
                    readOnly: true,
                    disabled: true,
                  }}
                  sx={{ width: '45%' }} 
                />
              </Stack>

              {loged_in_user.role === 'super' && <><Stack spacing={3}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                  <TextField
                    labelId="agent"
                    select
                    value={agent}
                    label="Agent"
                    defaultValue=""
                    onChange={(e) => setAgent(e.target.value)}
                    InputProps={{
                      readOnly: loged_in_user.role === 'Agent' ? true : false,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Iconify icon="mdi:face-agent" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '45%' }} 
                  >
                    {createAgentList('Agent')}
                  </TextField>
                  <TextField
                    labelId="subAgent"
                    select
                    value={subAgent}
                    label="Sub Agent"
                    defaultValue=""
                    onChange={(e) => setSubAgent(e.target.value)}
                    InputProps={{
                     // readOnly: loged_in_user.role === 'Agent' ? true : false,
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <Iconify icon="material-symbols:support-agent" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ width: '45%' }} 
                  >
                    {createAgentList('SubAgent')}
                  </TextField>
                </Stack>

                <TextField
                  error={creditAmountError}
                  name="creditAmount"
                  label="Credit Amount"
                  onChange={(e) => setCreditAmount(e.target.value)}
                  value={creditAmount}
                  type="number"
                  InputProps={{
                    readOnly: loged_in_user.role === 'Agent' ? true : false,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="solar:money-bag-broken" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="systemCut"
                  label="System Cut (Percentage %)"
                  onChange={(e) =>
                    e.target.value < 0 || e.target.value > 100 ? setSystemCut(10) : setSystemCut(e.target.value)
                  }
                  value={systemCut}
                  type="number"
                  InputProps={{
                    readOnly: loged_in_user.role === 'Agent' ? true : false,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="fluent:screen-cut-20-regular" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="expiryDate"
                  label="Expiry Date"
                  onChange={(e) => setExpiryDate(e.target.value)}
                  value={expiryDate && expiryDate.length > 0 ? new Date(expiryDate).toISOString().split('T')[0] : null}
                  type="date"
                  InputProps={{
                    readOnly: loged_in_user.role === 'Agent' ? true : false,
                    startAdornment: (
                      <InputAdornment position="start">
                        <IconButton>
                          <Iconify icon="mdi:weather-date" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>

              <Stack direction={'row'} justifyContent={'space-between'}>
                {/* <TextField  name="branch_id" label="Branch Id" value={branches} onChange={(e) => setBranches(e.target.value)} /> */}
                <TextField
                  error={branchesError}
                  name="branch"
                  label="Branch Name"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  InputProps={{
                    readOnly: loged_in_user.role === 'Agent' ? true : false,
                  }}
                />
                <Button variant="outlined" onClick={addBranchRow}>
                  Add
                </Button>
                <Button variant="outlined" onClick={resetBranch}>
                  Reset
                </Button>
              </Stack>

               <div>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead
                    style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'center', fontWeight: 'bold' }}
                  >
                    Branches
                  </thead>
                  <tbody>
                    {branches?.map((r) => (
                      <tr>
                        <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'left' }}>{r}</td>
                        <td style={{ border: '1px solid #dddddd', padding: '8px', textAlign: 'right' }}>
                          <IconButton onClick={() => handleDeleteBranch(r)}>
                            <Iconify icon="eva:trash-outline" />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div></>
}

            </Stack>

            {loged_in_user.role === 'Agent' && (<>

<Divider sx={{ my: 3 }}>
<Typography variant="body2" sx={{ color: 'text.secondary' }}>
  Assign Sub Agent
</Typography>
</Divider>

              <Stack>
                <TextField
              labelId="subAgent"
              select
              value={subAgent}
              label="Sub Agent"
              defaultValue=""
              onChange={(e) => setSubAgent(e.target.value)}
              InputProps={{
               // readOnly: loged_in_user.role === 'Agent' ? true : false,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton>
                      <Iconify icon="material-symbols:support-agent" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ width: '45%' }} 
            >
              {createAgentList('SubAgent')}
            </TextField>
              </Stack></>
            )}

            <Stack direction="row" alignItems="center" justifyContent="space-around" sx={{ my: 2 }}>
              <FormLabel>Is Active</FormLabel>{' '}
              <Checkbox
                name="isActive"
                label="Is Active"
                value={isActive}
                defaultChecked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                inputProps={{
                  hidden: loged_in_user.role === 'Agent' ? true : false,
                }}
              />
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
              Save
            </LoadingButton>
            {/*end Registration Form*/}
          </StyledContent>
        </Container>
      </Dialog>
    </>
  );
}
